import { IThreeSixtyViewProps } from '../threeSixtyView/threeSixtyView.d';
import { Fetch } from './Fetch';

export class ThreeSixtyViewDataController {
    private static _instance: ThreeSixtyViewDataController;
    private readonly fetch: Fetch;
    private readonly magicLink: string;

    static instance(magicLink) {
        return this._instance || (this._instance = new this(magicLink));
    }

    constructor(magicLink: string) {
        this.fetch = new Fetch();
        this.magicLink = magicLink;
    }
    
    public getThreeSixtyViewData(colorCode: number, masterArticleNo: string, 
        showCase: string = null): Promise<IThreeSixtyViewProps> {
        const apiUrl = this.magicLink + 'api/showcase/getthreesixtyviewdata';

        return this.fetch.fetch(apiUrl, masterArticleNo, colorCode.toString(), showCase)
            .then((response) => {
                if (response) {
                    return response;
                }
                else {
                    // ping PDP and hide SCS fragment, 
                    // because selected does not have 3D animation
                    window.shell.publishTo('SCS.Hide.Fragment', true);
                    return undefined;
                }
            });
    }
}
