import React from 'react';
import * as ThreeSixtyThumbnailsTypes from './threeSixtyThumbnails.d';
import './threeSixtyThumbnails.scss';
import * as helper from '../common/scs-html-helper';
import ArrowUpLeft from '../../Assets/svg/arrowUp';
import ArrowDownRight from '../../Assets/svg/arrowDown';

export default class ThreeSixtyThumbnails extends React.Component<ThreeSixtyThumbnailsTypes.IThreeSixtyThumbnailsProps,
ThreeSixtyThumbnailsTypes.IThreeSixtyThumbnailsState> {
    private forceControlBntFocus: boolean;

    constructor(props) {
        super(props);
        this.forceControlBntFocus = false;

        // Startup state:
        this.state = {
            activeIndex: 0,
            moveValue: 0,
            positionIndex: 0,
            maxPositionIndex: 0,
            slotSize: 0,
            displayThreeSixtyOverlay: process.env.NODE_ENV === 'test' ? true : false,
        };

        this.handleArrowUpLeftClick = this.handleArrowUpLeftClick.bind(this);
        this.handleArrowDownRightClick = this.handleArrowDownRightClick.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleThreeSixtyThumbnailSelected = this.handleThreeSixtyThumbnailSelected.bind(this);
        this.openThreeSixtyOverlay = this.openThreeSixtyOverlay.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    }
    
    componentDidMount() {
        window.shell.subscribeTo(
            'SCS.Open.Gallery3DView',
            (displayG3dOverlay: boolean) => {
                if(displayG3dOverlay)
                    this.openThreeSixtyOverlay();
            },
            'SCS.ThreeSixtyThumbnails');

        window.addEventListener('resize', this.handleWindowResize, false);
        this.init();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    
    public restartAnimation(): void {
        this.setState({
            activeIndex: 0,
            moveValue: 0,
            positionIndex: 0
        });

        this.init();
    }

    private openThreeSixtyOverlay(): void {
        this.setState({
            displayThreeSixtyOverlay: true
        });
    }

    private setSlotSize(): void {
        this.setState({slotSize: helper.isDeskTopView() ? 185 : 130});
    }

    private handleWindowResize(): void {
        this.setSlotSize();
    }

    private init(): void {
        this.setSlotSize();

        if(this.props.threeSixtyThumbnails.length > 3)
            this.setState({maxPositionIndex: this.props.threeSixtyThumbnails.length - 3});
        else
            this.setState({maxPositionIndex: 1});
    }
    
    private handleArrowUpLeftClick(): void {
        if (this.state.positionIndex <= 0) {
            this.setState({
                moveValue: 0,
                positionIndex: 0
            });
        }
        else {
            this.setState({
                moveValue: this.state.moveValue + this.state.slotSize,
                positionIndex: this.state.positionIndex - 1
            });
        }
    }

    private handleArrowDownRightClick(): void {
        if(this.state.positionIndex < this.state.maxPositionIndex) {
            this.setState({
                moveValue: this.state.moveValue - this.state.slotSize,
                positionIndex: this.state.positionIndex + 1
            });
        }
    }

    private prepareImageUrl(imgUrl: string): string {
        if (helper.isDeskTopView())
            return [imgUrl, '&width=110', '&height=110'].join('');

        return [imgUrl, '&width=80', '&height=80'].join('');
    }

    private getSlotStyle(): React.CSSProperties {
        if (helper.isDeskTopView())
            return { transform: 'translateY(' + this.state.moveValue + 'px' + ')' };

        return { transform: 'translateX(' + this.state.moveValue + 'px' + ')' };
    }

    private renderControls(): boolean { 
        return this.props.threeSixtyThumbnails && this.props.threeSixtyThumbnails.length > 3;
    }

    private tSlotImageContainerStyleClass(index: number): string {
        return this.state.activeIndex === index ? 't_slot_image_container selected' : 't_slot_image_container';
    }

    private handleThreeSixtyThumbnailSelected(threeSixty, index: number): void {
        this.setState({
            activeIndex: index
        });

        this.props.onThreeSixtyThumbnailSelected(threeSixty);

        if (this.forceControlBntFocus) { // step 2
            const start_stop_button = document.querySelector('.start_stop_button');
            if (start_stop_button && window.shell.tabNav) {
                window.shell.tabNav.focus(start_stop_button);
            }

            this.forceControlBntFocus = false;
        }
    }

    private getAltTag(tsLabel: string): string {
        if (!this.props.productInfo)
            return '';
        const localizationKey = 
            this.props.productInfo.l10n.localizationKey  + ', ';
        const categoryName = 
            this.props.productInfo.categoryName ?  ', ' + this.props.productInfo.categoryName : '';
        const designation = 
            this.props.productInfo.designation ? ', ' + this.props.productInfo.designation : '';
        const colorName = 
            this.props.productInfo.colorName ? ', ' + this.props.productInfo.colorName : '';
        return localizationKey + tsLabel + ', ' + categoryName + designation + colorName;
    }

    private setGroupAtt(el: HTMLDivElement): void {
        if (el) {
            if (!el.hasAttribute('group')) {
                el.toggleAttribute('group');
            }
        }
    }

    private setFocusAtt(el: HTMLDivElement, order: number): void {
        if(el) {
            el.setAttribute('focusable', '');
            el.setAttribute('order', order.toString());
        }
    }

    private handleOnKeyDown(event): void {
        // prevent page jump down when 3dG is closed with Space btn., click
        if (event.code === 'Space') {
            event.preventDefault();
            this.forceControlBntFocus = true; // step 1
        }
    }

    private renderThumbnails() {
        return (
            this.props.threeSixtyThumbnails.map((threeSixty, index) => {
                const tsLabel = helper.decodeHTML(threeSixty.label);

                return (
                    <div style={this.getSlotStyle()}
                        ref={(el)=> this.setFocusAtt(el, index + 1)}
                        data-testid={'thumbnail_slot'}
                        key={index} className={'thumbnail_slot'}
                        onClick={() => this.handleThreeSixtyThumbnailSelected(threeSixty, index)}
                        onKeyDown={this.handleOnKeyDown}>
                            <div className={this.tSlotImageContainerStyleClass(index)}>
                                <img alt={this.getAltTag(tsLabel)} className={'t_slot_image'} 
                                    src={this.prepareImageUrl(threeSixty.imageUrlArray[0])}/>
                            </div>
                        <div className={'t_slot_label'}>
                            {tsLabel}
                        </div>
                    </div>
                );
            })
        );
    }

    public render() {
        return (
            (this.state.displayThreeSixtyOverlay && 
                this.props.threeSixtyThumbnails.length && this.props.threeSixtyThumbnails.length > 0) ?
            <div className={'three_sixty_thumbnails_container'} data-testid={'three_sixty_thumbnails_container'}
                ref={(el)=> this.setGroupAtt(el)}>
                {this.renderControls() &&
                    <div className={'t_arrow_left'} onClick={this.handleArrowUpLeftClick}
                    data-testid={'t_arrow_left'}>
                        <ArrowUpLeft />
                    </div>}
                <div className={'three_sixty_thumbnails'} data-testid={'three_sixty_thumbnails'}
                    ref={(el)=> this.setGroupAtt(el)}>
                    {this.renderThumbnails()}
                </div>
                {this.renderControls() &&
                    <div className={'t_arrow_right'} onClick={this.handleArrowDownRightClick}
                    data-testid={'t_arrow_right'}>
                        <ArrowDownRight />
                    </div>}
            </div> : <></>
        );
    }
}
